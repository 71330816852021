import { Poppins } from "next/font/google";
import { FirstPage } from "@/components/FirstPage/FirstPage";
import { ThirdPage } from "@/components/ThirdPage/ThirdPage";
import { Footer } from "@/components/Footer/Footer";
import { FourthPage } from "@/components/FourthPage/FourthPage";
import { FifthPage } from "@/components/FifthPage/FifthPage";
import { SecondPage } from "@/components/SecondPage/SecondPage";
// import AOS from 'aos'
// import 'aos/dist/aos.css'
import { useEffect } from "react";
import Firefly from "@/components/Firefly";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import Script from "next/script";
import { NextSeo } from "next-seo";
import fb from "@/utils/fb";
import Head from "next/head";

const poppins = Poppins({
  subsets: ["latin"],
  weight: ["200", "300", "400", "600", "700"],
});

const eventId = Date.now();

export default function Home() {
  // useEffect(() => {
  //     AOS.init({
  //         duration: 1500,
  //     })
  // }, [])

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      fb("PageView", "PageView" + eventId);
    }
  }, [eventId]);

  return (
    <>
      <Head>
        <link rel="icon" href="/new-logo-voefly.webp" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>
      <NextSeo
        title={`VoeFly - Passagens Aéreas e Pacotes com Desconto`}
        description="Chega de ficar procurando em qualquer lugar. ✈️ As melhores passagens aéreas baratas você encontra na VoeFly. Aproveite nossas ofertas e promoções!"
        canonical="https://www.voefly.tur.br/"
        keywords="Passagens aéreas baratas, Pacotes de viagem com desconto, Promoções de passagens aéreas, Ofertas de viagens, Passagens aéreas promocionais, VoeFly passagens, Desconto em passagens aéreas, Passagens aéreas internacionais, Viagens econômicas, Passagens aéreas nacionais"
      />

      <main className={`${poppins.className}`}>
        {process.env.NODE_ENV === "production" && (
          <>
            <Script id="facebook-pixel-page" strategy="afterInteractive">
              {`
                            fbq('init', '${
                              process.env.NEXT_PUBLIC_FB_PIXEL_ID
                            }');
                            fbq('track', 'PageView', {}, {eventID: '${
                              "PageView" + eventId
                            }'});
                        `}
            </Script>
          </>
        )}

        <FloatingWhatsApp
          phoneNumber="+5518996505452"
          accountName="Agência VoeFly"
          placeholder=" Digite uma mensagem"
          darkMode={false}
          allowClickAway={true}
          allowEsc={true}
          chatMessage={`Olá! 🤝${"\n"}Como podemos te ajudar?`}
          statusMessage="Tempo de resposta: 1 hora"
          notification={true}
          notificationDelay={10}
        />
        <Firefly />
        <FirstPage id="home" />
        <SecondPage />
        <ThirdPage id="depoimentos" />
        <FourthPage />
        <FifthPage />
        <Footer />
      </main>
    </>
  );
}
